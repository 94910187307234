.packages-container {
  background-color: #f5f5f5;
  padding: 20px 0; 
}

/* Title section */
.packages-title {
  text-align: center;
  margin-bottom: 2%;
  padding-top:2%;
  
}

/* Cards section */
.packages-cards {
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
}

.packages-grid {
  display: flex;
  width: 80%;
  gap: 30px;
  justify-content: space-around;
  
}
.benefits-list {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  color: #555;
}

.benefits-list p {
  margin-bottom: 15px; /* Spacing between paragraphs */
  line-height: 1.6; /* Improve readability */
}

.layer{
display: flex;
justify-content: center;
}

.layer_2{
  width: 78%;
 
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .packages-grid {
    flex-direction: column;
    align-items: center;
  }

  .package-card {
    width: 100%; /* Adjust width for mobile */
    margin-bottom: 20px;
  }
  .layer_2{
    width: 80%;
  }
}
