/* Modal overlay with a dimmed background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7); /* Dark background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal content box */
.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 80%;
    max-width: 700px;
}

/* Close button */
.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
}

/* Fade background effect */
body.modal-open {
    overflow: hidden;
}
